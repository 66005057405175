.breadcrumb {
	background-color: transparent !important;
	font-size: 0.75rem;
	padding: 0 !important;
	margin-bottom: 0 !important;
}

.breadcrumb-item {
	padding-top: 0 !important;
}
