.custom-input-group {
	.custom-input-prefix {
		font-size: 14px;
		padding: 8px 0px 8px 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border: 1px solid #d0d5dd;
		background: #fff !important;
		border-right: 0px !important;
	}

	.custom-input-prefix + .custom-form-input {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		border-left: 0px;
	}
}

.custom-form-input,
.custom-form-input.form-control {
	display: flex;
	padding: 10px 12px;
	align-items: center;
	gap: 8px;
	width: 100%;
	min-height: 40px;
	font-size: 14px;
	font-weight: 300;
	align-self: stretch;
	border-radius: 8px !important;
	border: 1px solid #d0d5dd;
	background: #fff;
	line-height: 20px;
	position: relative;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

	&-sm {
		height: 33px !important;
	}
}

.custom-form-input {
	&::placeholder {
		text-transform: uppercase;
	}
}

.custom-form-typeahead {
	&-sm {
		.form-control {
			height: 33px !important;
		}
	}

	.form-control {
		display: flex;
		padding: 10px 12px;
		align-items: center;
		gap: 8px;
		width: 100%;
		min-height: 40px;
		font-size: 13px;
		font-weight: 300;
		align-self: stretch;
		border-radius: 8px;
		border: 1px solid #d0d5dd;
		background: #fff;
		line-height: 20px;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	}

	.custom-form-header {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #eaecf0;
		background-color: #f9fafb;
		margin-left: 0px;
		padding: 8px 16px;
		color: #667085;
		font-size: 12px;
		font-weight: 600;
		position: sticky;
		top: 0px;
		z-index: 99;
		text-transform: uppercase;
	}

	.custom-form-typeahead-item {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #eaecf0;
		margin-left: 0px;
		padding: 8px 16px;
		color: #1c212d;
		font-size: 12px;
		font-weight: 600;

		svg {
			color: green;
			width: 18px;
			height: 18px;
		}

		> div {
			overflow: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none !important;
			}
		}

		&:hover {
			background-color: #f9fafb;
		}
	}

	.dropdown-menu {
		display: grid !important;
		padding: 0px !important;
		overflow-x: hidden !important;
		max-height: 300px !important;

		@media (max-width: 991.98px) {
			position: absolute !important;
			top: 45px !important;
			left: 0px !important;
			transform: none !important;
		}
	}

	.dropdown-menu .dropdown-item {
		display: contents;
	}
}

.toggle-button {
	background: transparent;
	position: absolute;
	border: 0;
	bottom: 0;
	right: 0;
	top: 0;
	padding: 0 10px;
}

.custom-form-label {
	@media (min-width: 420px) {
		min-height: 22px;
	}
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: var(--primary);
	border-color: var(--primary);

	&:hover:before {
		// compiled to: .form__checkbox-label:hover:before
		background-color: var(--primary);
		height: 40px;
	}
}

.ant-checkbox-disabled .ant-checkbox-inner {
	background-color: transparent;
	border-color: #d0d5dd;
}

.custom-form-radio-button {
	input[type="radio"] {
		accent-color: var(--primary);
	}

	input[type="radio"]:checked:after {
		accent-color: var(--primary);
	}

	@media screen and (max-width: 768px) {
		margin-left: 20px;
	}
}

.custom-file-upload {
	display: flex;
	gap: 8px;
	flex-direction: column;

	.custom-file-upload-input {
		display: flex;
		align-items: baseline;

		p {
			color: #667085;
			font-size: 14px;
			line-height: 20px;
		}
	}

	.custom-uploded-file__icon {
		width: 40px;
		height: 40px;
		padding: 4px;
		min-width: 40px;
		border-radius: 50%;
		background-color: #fae8e8;

		svg {
			width: 100%;
			height: 100%;
			color: #ff6600;
			padding: 8px;
			border-radius: 50%;
			background-color: #FFD0B0;
		}
	}

	.custom-uploded-file {
		width: 100%;
		gap: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 8px;
		padding: 8px 16px;
		border: 1px solid #ff6600;
		background: var(--white);

		> div {
			display: flex;
			gap: 16px;
			align-items: center;
		}

		h2 {
			color: #344054;
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 0px;
		}

		span {
			color: #667085;
			font-size: 12px;
			line-height: 18px;
		}
	}
}

.custom-select-placeholder {
	option[disabled]:first-child {
		color: #98a2b3; /* Placeholder text color */
		font-style: italic; /* Italics for placeholder */
	}
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
