.custom-notification-bar-parent-cont {
	background: #0000004d;
	width: 100vw;
	height: 100vh;
}

.custom-notification-bar {
	width: 300px;
	height: 100vh;
	position: fixed;
	right: 0;

	@media screen and (max-width: 992px) {
	}

	.custom-notification-bar-header {
		display: flex;
		height: 41px;
		padding: 8px 16px;
		background: #f9fafb;
		border-bottom: 1px solid #eaecf0;

		.custom-notification-bar-header-title {
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
		}

		svg {
			margin-left: auto;
			width: 20px;
			height: 20px;
			cursor: pointer;
		}
	}
	.custom-notification-bar-body {
		overflow-y: scroll;
		height: calc(100vh - 40px);
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none;
		background: #f9fafb;

		.custom-notification-bar-date-header {
			padding: 4px 16px;
			background: #f9fafb;
			border-bottom: 1px solid #eaecf0;
			font-size: 12px;
			font-weight: 600;
			line-height: 18px;
		}
	}
}

.custom-notification-card {
	display: flex;
	background: white;
	border-bottom: 1px solid #eaecf0;
	padding: 24px 16px;

	.custom-notification-card-date-cont {
		display: flex;
		align-items: center;
		color: #98a2b3;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		margin: 8px 0px;

		:nth-child(2) {
			margin-left: auto;
		}
	}

	.custom-notification-card-icon-cont {
		width: 32px;
		margin-right: 8px;
		border-radius: 50%;
		height: 32px;
		display: grid;
		place-items: center;
		background: #fffaeb;

		svg {
			height: 13px;
			color: #e85d00;
		}
	}

	.custom-notification-card-serivce-cont {
		background-color: #f2f4f7;
		padding: 8px;
		display: flex;
		border-radius: 4px;
		align-items: center;

		strong {
			font-size: 12px;
			font-weight: 700;
			line-height: 18px;
		}

		a {
			margin-left: auto;
			color: #2970ff;
			border-bottom: 1px solid #2970ff;

			&:hover {
				cursor:pointer;
			}
		}
	}
}
