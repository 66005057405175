@import "../../stylesheets/layout/layout-variables";

.closed-sidebar {
	.custom-header-logo {
		padding-left: 0px !important;
	}
}

.custom-header-logo {
	padding-left: calc($app-sidebar-width - 7px)  !important;
}

.mazak-profile-image-cont {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	overflow: hidden;
	margin: 10px !important;
}

.mazak-profile-image {
	width: 100%;
	height: 100%;
	object-fit: scale-down;
	border-radius: 50%;
}

.custom-mobile-view-only {
	@media screen and (min-width: 992px) {
		display: none;
	}
}

.custom-desktop-view-only {
	@media screen and (max-width: 992px) {
		display: none;
	}
}
