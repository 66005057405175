.custom-modal-close {
  width: 25px;
  height: 25px;
  color: #1C212D;
  border: none;
  background: none;

  svg {
    width: 24px;
    height: 24px;

    &:hover {
      color: #494D57;
    }
  }
}

.custom-modal-prev {
  width: 32px;
  height: 32px;
  padding: 8px;
  margin-right: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D0D5DD;
  background: #FFFFFF;

  svg {
    width: 16px;
    height: 16px;

    &:hover {
      color: #494D57;
    }
  }
}

.custom-modal {
  max-width: 100vw;
  margin: 20px auto;

  p {
    font-weight: 500;
    text-align: left;
    margin-bottom: 4px;
  }

  &.modal-dialog {
    border-radius: 8px;
    overflow: hidden;
  }

  .modal-header,
  .modal-footer {
    background: #FFFFFF;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
  }

  .modal-body {
    background: #F9FAFB;

    &-default {
      padding: 24px 14px;
    }

    // for warning & confirmation message modals
    &-notification {
      padding: 24px 16px;
    }
  }

  .modal-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #1C212D;
  }

  .btn {
    border-radius: 8px;
  }

  .btn-danger {
    background: #CB171A;

    &:hover {
      background: #D54548;
    }
  }

  .btn-outline-secondary {
    color: #344054;
    border-color: #D0D5DD;

    &:hover {
      color: white;
    }
  }
}

.custom-modal-error,
.custom-modal-success,
.custom-modal-warning {
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 12px;
  white-space: pre-line;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  p {
    margin-bottom: 5px;
  }
}

.custom-modal-warning {
  border: 1px solid #FEC84B;
  background: #FFFCF5;
  color: #DC6803;

  p {
    color: #B54708;
  }
}

.custom-modal-error {
  border: 1px solid #DC6466;
  background: #FDF7F7;
  color: #B91518;

  p {
    color: #901012;
  }
}

.custom-modal-success {
  border: 1px solid #6CE9A6;
  background: #F6FEF9;
  color: #039855;

  p {
    color: #027A48;
  }
}

.ant-modal-content {
  padding: 0 !important;
  border-radius: 10px !important;
  overflow: visible;
  max-width: 100vw;

  .modal-header {
    font-weight: bold;
    border-bottom: none;
  }
}

.modal-searchbar-wrapper {
  border-color: #D9D9D9;

  &:hover, &:focus {
    border-color: #D9D9D9 !important;
  }
}

.items-modal {
  .btn {
    border-radius: 0px;
  }
}