.custom-button {
	font-weight: 400 !important;
	display: inline-flex !important;
	align-items: center !important;
	gap: 4px !important;
	border-radius: 8px !important;
	padding: 6px 14px 6px 12px !important;
	justify-content: center !important;

	.btn-md {
		font-size: 14px !important;
		padding: 6px 14px 6px 12px !important;
	}

	svg,
	img {
		margin-right: 4px;
		width: 14px;
		height: 14px;
	}

	&__white {
		border: 1px solid #d0d5dd !important;
		color: #344054 !important;
		background-color: #ffffff !important;
		transition: background-color 0.4s linear;
		&__light {
			border: 1px solid #d0d5dd !important;
			color: #344054 !important;
			background-color: #ffffff;
			transition: background-color 0.4s linear;
			&:hover {
				background-color: #f9fafb;
			}
			&:active {
				background-color: #f9fafb;
			}
		}
	}

	&__primary {
		border-color: var(--primary);
		color: #ffffff;
		background-color: var(--primary);
	}

	&__borderless {
		border: none !important;
		color: #667085;
		background-color: transparent;

		&:hover,
		&:active,
		&:disabled {
			background-color: transparent !important;
			color: #344054 !important;
		}
	}
}
