// Navigation Menu Vertical

.vertical-nav-menu {
	position: relative;

	&::after {
		content: " ";
		pointer-events: none;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
	}

	&:nth-child(1) {
		min-height: 60vh;
		border-bottom: 1px solid #494d57;
	}

	.metismenu-container,
	.metismenu-item {
		padding: 0;
		margin-bottom: 4px;
		position: relative;
	}
	.metismenu-container {
		list-style: none;
		height: 0;

		&:before {
			opacity: 0;
			transition: opacity 300ms;
		}

		.metismenu-link {
			transition: transform 0.2s, height 300ms, color 300ms,
				background-color 300ms;
			display: block;
			line-height: 2.5rem;
			font-weight: 600;
			height: fit-content;
			padding: 0 $layout-spacer-x 0 40px;
			position: relative;
			@include border-radius($border-radius);
			color: white;

			&:hover {
				background: #141720;
				text-decoration: none;
				//transform: scale(1.02);

				i.metismenu-icon {
					color: #495057;
				}

				i.metismenu-state-icon {
					opacity: 1;
				}
			}

			&.active {
				color: white;
				i.metismenu-icon {
					color: white;
				}
			}

			&.has-active-child,
			&.active {
				font-weight: bold;
				background: var(--primary);
			}
		}

		i.metismenu-state-icon,
		i.metismenu-icon {
			text-align: center;
			width: 34px;
			height: 34px;
			font-size: 20px;
			line-height: 34px;
			position: absolute;
			left: 5px;
			top: 50%;
			margin-top: -17px;
			transition: color 300ms;

			::before {
				width: 16px;
				height: 16px;
			}
		}

		i.metismenu-state-icon {
			transition: transform 300ms;
			left: auto;
			right: 0;

			&.rotate-minus-90 {
				transform: rotate(-180deg);
			}
		}

		.metismenu-container {
			transition: padding 300ms;

			&.visible {
				height: 100%;
				padding: 0.5em 0 0 2rem;

				&:before {
					content: "";
					height: 100%;
					opacity: 1;
					width: 3px;
					background: #141720;
					position: absolute;
					left: 20px;
					top: 0;
					@include border-radius(15px);
				}

				& > .metismenu-item > .metismenu-link {
					height: fit-content;
					line-height: 20px;
					color: $gray-600;
					margin: 0 0 3px;
					cursor: pointer;
					padding: 8px 16px !important;

					&:hover {
						color: $primary;
					}

					.metismenu-icon {
						display: block;
					}

					&.active {
						color: white;
						background: $primary;
					}
				}
			}

			.metismenu-item > .metismenu-link {
				height: 0;
				overflow: hidden;
			}
		}
	}
}
